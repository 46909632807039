// Styles
import './subheader.scss';

// Types
import { SubheaderProps } from '../../types/interface';

const Subheader = ({ modifyClass, num, title }: SubheaderProps): JSX.Element => {

  return (
    <div className={modifyClass ? `subheader ${modifyClass}` : "subheader"}>
      <span>{num}</span>
      <h2>{title}</h2>
    </div>
  )
}

export default Subheader;
