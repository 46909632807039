import './project.scss';

const Projects = () => {
  return (
    <>
      Projects Page
    </>
  );
};

export default Projects;
