/**
* @name DownloadPDF
* @since  Oct-26-21
* @version 1.0.0
* @author Matthew Day <matt@matthewday.net>
* @property {React.SVGProps<SVGSVGElement>}
*
* @returns {JSX.Element} SVG logo icon
*/
const DownloadPDF = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      height="100px"
      width="100px"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      // xml:space="preserve"
    >
      <g>
        <path
          d="M79.515,14.575c0.124,0.142,0.304,0.223,0.492,0.223c0.19,0,0.368-0.081,0.494-0.223l4.55-4.588   c0.172-0.195,0.214-0.471,0.108-0.708c-0.108-0.235-0.344-0.387-0.603-0.387h-1.631V5.658C82.926,5.294,82.633,5,82.269,5h-4.523   c-0.364,0-0.658,0.294-0.658,0.658v3.234h-1.63c-0.259,0-0.495,0.152-0.601,0.387c-0.107,0.237-0.063,0.513,0.108,0.708   L79.515,14.575z">
        </path>
        <path
          d="M57.676,45.883c-1.881-2.097-3.739-4.44-5.412-6.548c-0.789-0.996-1.544-1.946-2.25-2.809l-0.052-0.063   c1.034-2.945,1.625-5.355,1.757-7.165c0.335-4.611-0.18-7.581-1.573-9.078c-0.941-1.013-2.354-1.366-3.683-0.919   c-0.952,0.319-2.241,1.174-2.978,3.432c-1.098,3.366-0.566,9.327,2.556,14.257c-1.393,3.648-3.335,7.84-5.48,11.831   c-4.085,1.432-7.341,3.315-9.678,5.599c-3.053,2.98-4.294,5.938-3.406,8.114c0.547,1.348,1.812,2.152,3.383,2.152   c1.094,0,2.277-0.395,3.422-1.141c2.891-1.89,6.666-8.187,8.691-11.836c4.189-1.308,8.325-1.843,10.427-2.046   c0.951-0.092,1.895-0.165,2.804-0.216c3.679,3.885,6.688,5.934,9.46,6.445c0.558,0.104,1.116,0.156,1.661,0.156   c2.259,0,4.126-0.901,4.997-2.41c0.657-1.14,0.644-2.47-0.037-3.65c-1.538-2.663-6.18-4.129-13.071-4.129   C58.718,45.86,58.206,45.867,57.676,45.883z M32.364,60.615c-0.547,0.357-1.109,0.57-1.505,0.57c-0.075,0-0.126-0.008-0.155-0.015   c-0.069-0.349,0.179-1.858,2.624-4.247c1.148-1.122,2.6-2.146,4.319-3.052C35.478,57.361,33.526,59.856,32.364,60.615z    M46.811,23.82c0.255-0.781,0.571-1.134,0.768-1.2c0.005-0.001,0.009-0.003,0.013-0.004c0.19,0.214,0.998,1.437,0.636,6.431   c-0.063,0.871-0.275,1.972-0.632,3.28C46.428,29.319,46.107,25.977,46.811,23.82z M53.062,46.181   c-1.862,0.179-4.698,0.548-7.862,1.297c1.227-2.45,2.363-4.933,3.312-7.234c0.329,0.412,0.666,0.835,1.008,1.268   c1.145,1.443,2.431,3.065,3.764,4.649L53.062,46.181z M69.251,51.739c0.052,0.09,0.051,0.126,0.038,0.15   c-0.147,0.255-0.812,0.66-1.969,0.66c-0.323,0-0.667-0.033-1.022-0.099c-1.444-0.267-3.128-1.265-5.135-3.045   C66.609,49.665,68.81,50.978,69.251,51.739z">
        </path>
        <path
          d="M65.093,5H19.813c-2.775,0-5.03,2.249-5.03,5.031v79.938c0,2.779,2.255,5.031,5.03,5.031h60.373   c2.781,0,5.03-2.252,5.03-5.031V25.124L65.093,5z M65.093,12.113l13.011,13.011H65.093V12.113z M80.187,89.969H19.813V10.031   h40.249v15.093c0,2.779,2.25,5.031,5.03,5.031h15.094V89.969z">
        </path>
        <path
          d="M41.688,69.355c-0.411-0.363-0.904-0.627-1.464-0.782c-0.553-0.158-1.345-0.238-2.354-0.238h-3.349   c-0.637,0-1.117,0.146-1.428,0.437c-0.314,0.294-0.475,0.77-0.475,1.413v9.932c0,0.578,0.143,1.028,0.425,1.34   c0.287,0.32,0.669,0.483,1.135,0.483c0.446,0,0.822-0.163,1.116-0.485c0.288-0.316,0.435-0.772,0.435-1.355v-3.397h2.141   c1.653,0,2.923-0.361,3.772-1.074c0.864-0.722,1.303-1.79,1.303-3.175c0-0.645-0.106-1.235-0.317-1.755   C42.416,70.172,42.1,69.721,41.688,69.355z M39.524,73.508c-0.187,0.252-0.454,0.429-0.818,0.543   c-0.387,0.12-0.88,0.182-1.468,0.182H35.73v-3.438h1.508c1.359,0,1.91,0.271,2.129,0.492c0.292,0.312,0.434,0.711,0.434,1.218   C39.801,72.923,39.708,73.26,39.524,73.508z">
        </path>
        <path
          d="M54.059,69.565c-0.543-0.475-1.157-0.804-1.825-0.977c-0.65-0.168-1.438-0.254-2.339-0.254h-3.401   c-0.629,0-1.099,0.151-1.398,0.451s-0.451,0.77-0.451,1.398v9.448c0,0.443,0.039,0.798,0.119,1.083   c0.091,0.324,0.287,0.577,0.582,0.752c0.281,0.17,0.675,0.252,1.201,0.252h3.401c0.602,0,1.152-0.039,1.635-0.117   c0.492-0.08,0.959-0.22,1.387-0.415c0.432-0.197,0.834-0.463,1.199-0.791c0.457-0.42,0.838-0.903,1.132-1.438   c0.291-0.533,0.51-1.138,0.648-1.796c0.138-0.652,0.208-1.383,0.208-2.17C56.156,72.583,55.449,70.757,54.059,69.565z    M51.786,78.602c-0.169,0.147-0.372,0.265-0.604,0.348c-0.243,0.086-0.479,0.141-0.704,0.162c-0.237,0.022-0.572,0.034-0.996,0.034   h-1.728V70.9h1.473c0.771,0,1.432,0.084,1.966,0.249c0.499,0.152,0.929,0.521,1.278,1.099c0.358,0.591,0.54,1.508,0.54,2.727   C53.011,76.694,52.6,77.913,51.786,78.602z">
        </path>
        <path
          d="M65.899,68.335h-6.152c-0.41,0-0.742,0.061-1.014,0.186c-0.291,0.132-0.508,0.347-0.646,0.64   c-0.128,0.274-0.19,0.609-0.19,1.024v9.914c0,0.597,0.146,1.056,0.431,1.364c0.291,0.316,0.671,0.477,1.129,0.477   c0.449,0,0.826-0.158,1.12-0.471c0.286-0.309,0.431-0.77,0.431-1.37v-3.995h4.048c0.453,0,0.808-0.109,1.053-0.326   c0.255-0.225,0.384-0.527,0.384-0.899c0-0.371-0.126-0.674-0.376-0.9c-0.243-0.222-0.6-0.334-1.061-0.334h-4.048v-2.796h4.892   c0.479,0,0.848-0.115,1.097-0.343c0.255-0.232,0.384-0.541,0.384-0.918c0-0.369-0.129-0.675-0.385-0.911   C66.744,68.449,66.376,68.335,65.899,68.335z">
        </path>
      </g>
    </svg>
  );
}

export default DownloadPDF;
