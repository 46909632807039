import Routes from '../../routes';
import './main.scss';

function Main() {
  return (
    <main>
      <Routes />
    </main>
  );
}

export default Main;