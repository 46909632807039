import SVGImage, { NAVICON } from '../../../components/svg';

// JSX
import SliderImage from './sliderImage';

// Types
import { Colour, NavDirection } from "../../../types/enum";
import { SliderChildProps } from "../../../types/type";

const DefaultSlider = ({
    action,
    bgNavRight,
    count=0,
    data,
    format,
    lastSlideNumber
  }: SliderChildProps): JSX.Element => {
  const graphics = data.map((graphic, index) => (
    <SliderImage
      alt={graphic.alt}
      key={`${graphic.name}-${index}`}
      prefix={graphic.prefix}
      name={graphic.name}
    />
  ));

  return (
    <>
      <div className="slider__graphic">{graphics[count]}</div>
      <div className="slider__caption">
        <p className="slider__caption-title">{data[count].title}</p>
        <p className="slider__caption-subtitle">{data[count].subtitle}</p>
      </div>
      <div className="slider__navs-container">
        <div className="y-wrap y-wrap--inner">
          <div className="slider__navs">
            <div className="count-container">
              <span className="count-container__position">{count + 1}</span>
              <span className="count-container__of">&#8212;</span>{/* TODO use graphic instead of HTML entity */}
              <span>{lastSlideNumber}</span>
            </div>
            <div className="slider__navs-nav">
              <SVGImage
                action={() => action(count - 1)}
                className="slider__nav"
                color={Colour.Black}
                direction={NavDirection.Left}
                id={`${format}-nav-left`}
                type={NAVICON}
              />
              <SVGImage
                action={() => action(count + 1)}
                bgColor={bgNavRight}
                className="slider__nav slider__nav--right"
                color={Colour.Black}
                direction={NavDirection.Right}
                id={`${format}-nav-right`}
                type={NAVICON}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultSlider;
