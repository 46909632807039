// JSX
import SVGImage, { LANDINGBANNER } from '../../components/svg';
import {
  Credentials,
  Growth,
  Intro,
  MdmcTeam,
  OurProcess,
  Projects,
  Testimonials,
  WhoWeAre
} from './subcomponents';

// Styles
import './landing.scss';

const Landing = (): JSX.Element => {

  return (
    <>
      <div className="banner-container">
        <SVGImage className="banner" type={LANDINGBANNER} />
      </div>
      <Intro />
      <Growth />
      <WhoWeAre />
      <OurProcess />
      <Projects />
      <MdmcTeam />
      <Credentials />
      {/* <Testimonials /> */}
    </>
  );
};

export default Landing;
