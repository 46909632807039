// Types
import { SliderImageProps } from "../../../types/interface";

const SliderImage = ({alt, name, prefix}: SliderImageProps): JSX.Element => {

  return (
    <img
      alt={alt}
      src={`https://res.cloudinary.com/samuraijane/image/upload/${prefix}/mdmc/${name}.jpg`}
    />
  )
};

export default SliderImage;
