import SVGImage, { NAVICON } from '../../svg';

// Types
import { Colour, NavDirection } from "../../../types/enum";
import { SliderChildProps } from '../../../types/type';

const SliderText = ({
    action,
    bgNavRight,
    count=0,
    data,
    lastSlideNumber
  }: SliderChildProps): JSX.Element => {
  return (
    <>
      <div className="slider__testimonial">
        <p className="slider__testimonial-quote">{data[count].quote}</p>
        <p className="slider__testimonial-author">{data[count].author}</p>
        <p className="slider__testimonial-title">{data[count].title}</p>
      </div>
      <div className="slider__navs-container">
        <div className="y-wrap y-wrap--inner">
          <div className="slider__navs">
            <div className="count-container">
              <span className="count-container__position">{count + 1}</span>
              <span className="count-container__of">&#8212;</span>{/* TODO use graphic instead of HTML entity */}
              <span>{lastSlideNumber}</span>
            </div>
            <div className="slider__navs-nav">
              <SVGImage
                action={() => action(count - 1)}
                className="slider__nav"
                color={Colour.Black}
                direction={NavDirection.Left}
                id='text-nav-left'
                type={NAVICON}
              />
              <SVGImage
                action={() => action(count + 1)}
                bgColor={bgNavRight}
                className="slider__nav slider__nav--right"
                color={Colour.White}
                direction={NavDirection.Right}
                id='text-nav-right'
                type={NAVICON}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default SliderText;