import { useEffect, useState } from 'react';

// JSX
import Cta from '../../components/cta';
import SVGImage, { CLOSEBTN, OPENBTN } from '../../components/svg';

// Styles
import './accordion.scss';

// Types
import { Colour, CtaType } from '../../types/enum';
import { AccordionProps } from '../../types/interface';

const Accordion = ({ action, description, id, isOpen, title }: AccordionProps): JSX.Element => {
  const [btnIcon, setBtnIcon] = useState<string>(OPENBTN);

  useEffect(() => {
    if (isOpen) {
      setBtnIcon(CLOSEBTN);
    } else {
      setBtnIcon(OPENBTN);
    }
  }, [isOpen]);

  return (
    <li className={`accordion ${!isOpen ? '' : 'accordion--no-border'}`}>
      <div className={`accordion__title-container`}>
        <div className="accordion__title">{title}</div>
        <SVGImage action={action} className="accordion__toggle" id={id} type={btnIcon} />
      </div>
      {isOpen && (
        <>
          <p>{description}</p>
          {/* <Cta
            action={() => alert('cta in FAQ')}
            className='accordion__cta'
            color={Colour.Blue}
            ctaText="Learn More"
            type={CtaType.Small}
          /> */}
        </>    
      )}
    </li>
  )
}

export default Accordion;
