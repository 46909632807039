// Types
import { CaptionProps } from '../../types/interface';

// Styles
import './style.scss';

const Caption = ({ city, state, title }: CaptionProps): JSX.Element => {

  return (
    <div className="caption">
      <span className="title">{title}</span>
      <span className="city-state">{`${city}, ${state}`}</span>
    </div>
  )
}

export default Caption;
