import { NavLink } from 'react-router-dom';

// JSX
import SVGImage, { CTA, LOGO } from '../../../components/svg';

// Types
import { Colour } from '../../../types/enum';

const LogoWithScrollToTop = (): JSX.Element => {
  return (
    <div className="footer__logo-with-scroll">
      <NavLink className="navs__active" to="/">
        <SVGImage className="svg-icon svg-icon__logo" color={Colour.White} type={LOGO} />
      </NavLink>
      <button className="footer__cta-container">
        <a href="#root"><SVGImage color={Colour.Black} type={CTA} /></a>
      </button>
    </div>
  )
};

export default LogoWithScrollToTop;