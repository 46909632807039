import { useEffect, useState } from 'react';

// JSX
import SVGImage, { NAVICON } from '../../../components/svg';
import Headshot from './headshot';
import SliderImage from './sliderImage';

// Types
import { BackgroundColor, Colour, NavDirection } from "../../../types/enum";
import { SliderChildProps } from "../../../types/type";

const MultipleSimultaneousSlider = ({action, bgNavRight, count, data, lastSlideNumber}: SliderChildProps): JSX.Element => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    // FRAGILE this calculation is based on the width set in `.y-wrap--inner`
    const viewportWidth = window.innerWidth;
    setWidth(viewportWidth * .93);
  }, []);

  const graphics = data.map((graphic, index) => graphic.prefix && (
    <SliderImage
      alt={graphic.alt}
      key={`${graphic.name}-${index}`}
      name={graphic.name}
      prefix={graphic.prefix}
    />
  ));

  const renderHeadshot = ((num: number) => {
    return (
      <Headshot
        image={graphics[num]}
        position={num === count ? 0 : 1}
        subtitle={data[num].subtitle}
        title={data[num].title}
      />
    )
  });

  const renderHeadshots = (num: number) => {
    // FRAGILE if the number of headshots change, initializations for headshots after `headshot_1` must be updated
    let headshot_1 = num;
    let headshot_2 = num + 1;
    let headshot_3 = num + 2;
    let headshot_4 = num + 3;
    let headshot_5 = num + 4;
    if (num === 3) {
      headshot_5 = 0;
    }
    if (num === 4) {
      headshot_4 = 0;
      headshot_5 = 1;
    }
    if (num === 5) {
      headshot_3 = 0;
      headshot_4 = 1;
      headshot_5 = 2;
    };
    if (num === 6) {
      headshot_2 = 0;
      headshot_3 = 1;
      headshot_4 = 2;
      headshot_5 = 3;
    };
    return (
      <>
        {renderHeadshot(headshot_1)}
        {renderHeadshot(headshot_2)}
        {renderHeadshot(headshot_3)}
        {renderHeadshot(headshot_4)}
        {renderHeadshot(headshot_5)}
      </>
    );
  };

  const renderNavBtn = ((direction: NavDirection) => {
    let nextIndex = count - 1;
    let postfix = 'left';
    if (direction === NavDirection.Right) {
      postfix = 'right';
      nextIndex = count + 1;
    }
    return (
      <SVGImage
        action={() => action(nextIndex)}
        bgColor={direction === NavDirection.Right ? bgNavRight : BackgroundColor.White}
        className={`slider__nav slider__nav--${postfix}`}
        color={direction === NavDirection.Right ? Colour.White : Colour.Black}
        direction={direction}
        id={`projects-nav-${postfix}`}
        type={NAVICON}
      />
    );
  });

  return (
    <>
      <div className="slider__graphic" style={{width: `${width}px`}}>
        <div className="slider__graphic-subcontainer">
          {renderHeadshots(count)}
        </div>
      </div>
      <div className="slider__navs-container">
        <div className="slider__navs">
          <div className="count-container">
            <span className="count-container__position">{count + 1}</span>
            <span className="count-container__of">&#8212;</span>{/* TODO use graphic instead of HTML entity */}
            <span>{lastSlideNumber}</span>
          </div>
          <div className="slider__navs-nav">
            {renderNavBtn(NavDirection.Left)}
            {renderNavBtn(NavDirection.Right)}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipleSimultaneousSlider;
