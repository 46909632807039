import Footer from './sectioning/footer/footer';
import Header from './sectioning/header/header';
import Main from './sectioning/main/main';
import { useViewport } from './hooks/useViewport';

const App = (): JSX.Element => {
  const { screenSize, width } = useViewport();
  const isMobileView = width && width < 992;
  (window as any).MDMC = {isMobileView, screenSize}; // ALLOWED, we opt for this over implementing Redux for one variable or using context with the same repeated code throughout
  return (
    <>
      <Header isMobileView={isMobileView} />
      <Main />
      <Footer />
    </>
  );
};

export default App;
