import hiringsData from '../../mocks/hiring.json'
import './hiring.scss';

const Hiring = () => {
  const hirings = hiringsData.map((hiringData, index) => {
    return (
      <li key={`${hiringData.id}-${index}`}>
        <div className="hiring-positions__tbd">
          <h3>{hiringData.position} – {hiringData.location}</h3>
        </div>
        {/* <SVGImage action={handleClick} className="svg-icon svg-icon--download-pdf" type={DOWNLOADPDF} /> */}
      </li>
    )
  })
  return (
    <>
      <p>Hiring page</p>
      <ul>{hirings}</ul>
    </>
  );      
};

export default Hiring;
