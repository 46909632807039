import { useEffect, useState } from "react";

// JSX
import DefaultSlider from "./subcomponents/defaultSlider";
// TODO combine these multipleSimultaneousSliders, in a hurry for now
import MultipleSimultaneousSlider from "./subcomponents/multipleSimultaneousSlider";
import MultipleSimultaneousSlider2 from "./subcomponents/multipleSimultaneousSlider2";
import SliderText from "./subcomponents/sliderText";

// Styles
import './slider.scss';

// Types
import { Format } from "../../types/enum";
import { SliderParentProps } from "../../types/type";

const Slider = ({bgNavRight, className, data, format}: SliderParentProps): JSX.Element => {
  const [count, setCount] = useState<number>(0);
  const [lastSlideIndex, setLastSlideIndex] = useState<number>(0);

  useEffect(() => {
    setLastSlideIndex(data.length - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSliderNav = (slide: number): void => {
    if (lastSlideIndex && slide > lastSlideIndex) {
      setCount(0);
    } else if (lastSlideIndex && slide < 0) {
      setCount(lastSlideIndex);
    } else {
      setCount(slide);
    }
  };

  return (
    <div className={`slider slider--${format}s ${className ? className : ''}`}>
      {format === Format.PROJECT && (
        <DefaultSlider
          action={handleSliderNav}
          bgNavRight={bgNavRight}
          count={count}
          data={data}
          format={format}
          lastSlideNumber={lastSlideIndex + 1}
        />
      )}
      {format === Format.HEADSHOT && (
        <MultipleSimultaneousSlider
          action={handleSliderNav}
          bgNavRight={bgNavRight}
          count={count}
          data={data}
          format={format}
          lastSlideNumber={lastSlideIndex + 1}
        />
      )}
      {format === Format.TEASER && (
        <MultipleSimultaneousSlider2
          action={handleSliderNav}
          bgNavRight={bgNavRight}
          count={count}
          data={data}
          format={format}
          lastSlideNumber={lastSlideIndex + 1}
        />
      )}
      {format === Format.TEXT && (
        <SliderText
          action={handleSliderNav}
          bgNavRight={bgNavRight}
          count={count}
          data={data}
          format={format}
          lastSlideNumber={lastSlideIndex + 1}
        />
      )}
    </div>
  );
};

export default Slider;
