export enum BackgroundColor {
  Blue = "#143199",
  Gray = "#787B85",
  LtGray = "rgba(96, 108, 153, 0.1)",
  White = "#FFFFFF"
}

export enum Colour {
  Black = "BLACK",
  Blue = "BLUE",
  White = "WHITE"
}

export enum CtaType {
  Large = "Large",
  Ordinary = "Ordinary",
  Small = "Small"
}

// TODO come up with more generic names since sliders can be interchanged amongst multiple different areas
export enum Format {
  HEADSHOT = "headshot",
  PROJECT = "project",
  TEXT = "text",
  TEASER = "teaser"
}

export enum NavDirection {
  Down = "Down",
  Left = "Left",
  Right = "Right",
  Up = "Up"
}

export enum ScreenSize {
  X_SMALL = "xsmall",  // 360px
  SMALL = "small",  // 576px
  INTERMEDIATE = "intermediate",  // 768px
  LARGE = "large",  // 992px
  X_LARGE = "xlarge"  // 1200px
}