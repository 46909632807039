import { NavLink } from 'react-router-dom';
import data from '../../../assets/json/navs.json';

const Navs = (): JSX.Element => {

  const navs = data.sort((a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  }).map((nav, index) => <NavLink key={`${nav}-${index}`} to=''>{nav.value}</NavLink>);

  return (
      <div className="footer__navs-container">
        <p>Links below coming soon...</p>
        {navs}
      </div>
    );
};

export default Navs;