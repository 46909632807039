// Types
import { Colour } from "../../../../types/enum";
import { Color } from "../../../../types/interface";

const Cta = ({color}: Color): JSX.Element => {
  let hexColor = '';
  if (color === Colour.Blue) {
    hexColor = '#2251FF';
  } else {
    // make it white
    hexColor = '#FFF';
  }

  return (
    <div style={{lineHeight: 0}}>
      <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14.5" cy="14" r="13.4" stroke={hexColor} strokeWidth="1.2" />
        <path d="M17.8946 17.3936L17.8946 10.6054L11.1064 10.6054" stroke={hexColor} strokeWidth="1.2" />
        <path d="M11.1052 17.3947L17.8934 10.6064" stroke={hexColor} strokeWidth="1.2" />
      </svg>
    </div>
  );
}

export default Cta;
