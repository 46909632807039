import Statistic from '../../../../components/statistic/statistic';
import './growth.scss';

const Intro = () => {
  const data = [
    {_id: 1, stat: '6', text: 'Our team currently operates in six states.'},
    {_id: 2, stat: '22', text: 'Cities across the Southeast.'},
    {_id: 3, stat: '400+', text: 'Projects handled with expertise and<br />integrity.'},
    {_id: 4, stat: '100', text: 'Hundreds of professional relationships and counting.'}
  ];

  const statistics = data.map((stat, index) => (
    <Statistic key={stat._id} stat={stat.stat} text={stat.text} />
  ))

  return (
    <div className="growth">
      <div className="y-wrap y-wrap--inner-alt">
        <div className="growth__outer-container">
          <div className="growth__inner-container">
            <h2>Consistent growth in numbers</h2>
            <ul className="growth__statistics">
              {statistics}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
