// Styles
import './statistic.scss';

// Types
import { StatisticProps } from '../../types/interface';


const Statistic = ({ stat, text }: StatisticProps): JSX.Element => {

  return (
    <li className="statistic">
      <span className="statistic__stat">{stat}</span>
      <p className="statistic__text" dangerouslySetInnerHTML={{__html: text}} />
    </li>
  )
}

export default Statistic;
