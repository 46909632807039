import './style.scss';

const Credential = () => {
  return (
    <>
      Credential Page
    </>
  );
};

export default Credential;
