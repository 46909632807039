// JSX
import Caption from '../../../../components/caption';
import Cta from '../../../../components/cta';

// Styles
import './intro.scss';

// Types
import { Colour } from '../../../../types/enum';

const Intro = (): JSX.Element => {
  return (
    <div className="y-wrap y-wrap--inner">
      <div className="intro">
        <h1>Building healthcare<br />and commercial<br />solutions with integrity.</h1>
        <p>We provide a high degree of integrity and professionalism with every project, devoted to completing your project no matter how restrictive the environment or the type of construction.</p>
        <Cta
          action={() => alert('hey')}
          className = 'intro__cta'
          color={Colour.Blue}
          ctaText="See Our Work"
          tempHref="#projects"
        />
        <Caption city="Melbourne" state="Florida" title="Hybrid Operating Room" />
      </div>
    </div>
  );
};

export default Intro;
