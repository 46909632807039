import { MouseEventHandler, useState } from 'react';
import { NavLink } from "react-router-dom";

// Hooks
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';

// JSX
import SVGImage, { LOGO } from '../../components/svg';
import DesktopView from '../../navigation/desktop/desktop';
import FlyoutMenu from '../../navigation/flyout/flyoutMenu';
import FlyoutButton from './subcomponents/flyoutButton';

// Styles
import './header.scss';

// Types
import { Colour } from '../../types/enum';
import { HeaderProps } from '../../types/interface';


function Header({ isMobileView }: HeaderProps): JSX.Element {

  const [isShowMobileNav, setIsShowMobileNav] = useState(false);

  useDisableBodyScroll(isShowMobileNav);

  const handleMobileNav: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (): void => {
    setIsShowMobileNav(!isShowMobileNav);
  };

  return (
    <>
      <header className='border-lt'>
        <div className={`y-wrap y-wrap--inner y-header-${isMobileView ? 'mobile' : 'desktop'}`}>
          <div className="nav__logo-container">
            <NavLink className="navs__active" to="/">
              <SVGImage className="svg-icon svg-icon__logo" color={Colour.White} type={LOGO} />
            </NavLink>
          </div>
          {/* {isMobileView && <FlyoutButton handleMobileNav={handleMobileNav} isShowMobileNav={isShowMobileNav} />}
          {isMobileView ?
            <FlyoutMenu handleMobileNav={handleMobileNav} isShowMobileNav={isShowMobileNav} /> :
            <DesktopView />
          } */}
        </div>
      </header>
    </>
  );
}

export default Header;
