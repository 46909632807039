import './style.scss';

interface LeaderImageProps {
  altText: string;
  imageURL: string;
  tagline: string;
}

const LeaderImage = ({ altText, imageURL, tagline }: LeaderImageProps): JSX.Element => {
  return (
    <div className="leader-image">
      <img alt={altText} src={imageURL} />
      <h1 className="leader-image__title" dangerouslySetInnerHTML={{__html: tagline}}></h1>
    </div>
  )
}

export default LeaderImage;