// Types
import { HeadshotProps } from "../../../types/interface";

const Headshot = ({image, isHorizontal, position, subtitle, title}: HeadshotProps): JSX.Element => (
  <div className={`slider__position slider__position--${position}`}>
    <div className={`slider__img-container slider__img-container--${isHorizontal ? 'horizontal' : 'vertical'}`}>{image}</div>
    <div className="slider__caption">
      <p className="slider__caption-title">{title}</p>
      <span className="slider__caption-subtitle">{subtitle}</span>
    </div>
  </div>
)

export default Headshot;
