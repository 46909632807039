import { useEffect, useState } from 'react';

// Data
import data from './mdmcTeam.json';

// JSX
import Headshot from '../../../../components/slider/subcomponents/headshot';
import Subheader from '../../../../components/subheader/subheader';
import Slider from '../../../../components/slider/slider';
import SliderImage from '../../../../components/slider/subcomponents/sliderImage';

// Styles
import './mdmcTeam.scss';

// Types
import { BackgroundColor, Format } from '../../../../types/enum';
import { AssetData } from '../../../../types/interface';

const MdmcTeam = (): JSX.Element => {
  const { isMobileView } = (window as any).MDMC;
  const [ teamData, setTeamData] = useState<AssetData[]>([]);

  useEffect(() => {
    const _teamData = data.filter(x => x.isShow);
    setTeamData(_teamData);
  }, []);

  // TODO put `graphics` and `<Headshot />` in a central file and import since we have the same code for these in more than one place
  // TODO make `position` optional, we hardcode it here but it's not needed in this context
  const renderHeadshot = ((alt: string, name: string, subtitle: string, title: string, prefix: string) => {
    const image = <SliderImage alt={alt} name={name} prefix={prefix} />;
    return (
      <Headshot
        image={image}
        position={1}
        subtitle={subtitle}
        title={title}
      />
    )
  });

  return (
    <div className="team" id="team">
      <div className="y-wrap y-wrap--inner">
        <div className="team__main">
          <Subheader num="04" title="The MDMc Team" />
          <div className="team__big-heading">Exceptional and trusty challengers</div>
        </div>
        {isMobileView && teamData.length && (
          <Slider bgNavRight={BackgroundColor.Gray} data={teamData} format={Format.HEADSHOT} />
        )}
        {!isMobileView && teamData.length && (
          <div className="not-mobile-team">
            {teamData.map((headshot, index) => (
              <div className="blah" key={index}>
                {renderHeadshot('tbd', headshot.name, headshot.subtitle, headshot.title, headshot.prefix)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MdmcTeam;
