const About = () => {
  return (
    <div className="y-wrap y-wrap--inner">
      <div className="content-block">
        <h1>About page</h1>
      </div>
    </div>
  );
};

export default About;
