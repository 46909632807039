// Types
import { Colour, NavDirection } from "../../../../types/enum";
import { NavBtnProps } from "../../../../types/interface";

const NavBtn = ({color, direction}: NavBtnProps): JSX.Element => {
  let hexColor = '';
  if (color === Colour.Black) {
    hexColor = '#071033';
  } else {
    hexColor = '#FFF';
  }

  const navIcon = {
    [NavDirection.Down]: '',
    [NavDirection.Left]: (() => (
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 1L1.5 7L7.5 13" stroke={hexColor} strokeWidth="1.2"/>
      </svg>
    ))(),
    [NavDirection.Right]: (() => (
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 13L6.5 7L0.5 1" stroke={hexColor} strokeWidth="1.2"/>
      </svg>
    ))(),
    [NavDirection.Up]: ''
  };

  return <>{direction && navIcon[direction]}</>;
}

export default NavBtn;
