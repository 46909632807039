import { useEffect, useState } from 'react';

// Data
import data from './our-process.json';

// Hooks
import { useViewport } from '../../../../hooks/useViewport';

// JSX
import Accordion from '../../../../components/accordion/accordion';
import Cta from '../../../../components/cta';
import Subheader from '../../../../components/subheader/subheader';
import SVGImage, { MEASURING } from '../../../../components/svg';

// Styles
import './our-process.scss';

// Types

import { Colour, ScreenSize } from '../../../../types/enum';
import { AccordionData } from '../../../../types/interface';

const Business = (): JSX.Element => {
  const { screenSize } = useViewport();
  const [activeAccordionId, setActiveAccordionId] = useState<string>();
  const [accordions, setAccordions] = useState<AccordionData[]>();
  const [lastAccordionId, setLastAccordionId] = useState<string>('tbd');

  useEffect(() => {
    const _accordions: AccordionData[] = data.filter(x => x.isShow);
    setAccordions(_accordions);
    const _lastAccordionId: AccordionData = _accordions[_accordions.length - 1];
    _lastAccordionId.id && setLastAccordionId(_lastAccordionId.id)
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.id;
    if (activeAccordionId === id) {
      setActiveAccordionId('');
    } else {
      setActiveAccordionId(id);
    }
  }

  const jsxAccordions = accordions?.map(accordion => (
    <Accordion
      action={handleClick}
      description={accordion.description}
      id={accordion.id}
      isOpen={activeAccordionId === accordion.id}
      key={accordion.id}
      title={accordion.title}
    />
  ));

  if (screenSize === ScreenSize.X_SMALL || screenSize === ScreenSize.SMALL) {
    return (
      <div className="our-process">
        <div className="y-wrap y-wrap--inner">
          <div className="our-process__main">
            <Subheader num="02" title="Our Process" />
            <div className="our-process__big-heading">We take great pride</div>
            <ul className="our-process__accordions">{jsxAccordions}</ul>
            <Cta
              action={() => alert('Coming soon, please check back later.')}
              className={`our-process__cta ${activeAccordionId === lastAccordionId ? 'our-process__cta--last' : ''}`}
              color={Colour.White}
              ctaText="See Our Services (coming soon)"
            />
          </div>
        </div>
        <div className="y-wrap y-wrap--after768">
          <SVGImage className="our-process__img-container" type={MEASURING} />
        </div>
      </div>
    );
  }

  if (screenSize === ScreenSize.INTERMEDIATE || screenSize === ScreenSize.LARGE || screenSize === ScreenSize.X_LARGE) {
    return (
      <div className="our-process our-process--intermediate">
        <div className="y-wrap y-wrap--inner">
          <Subheader num="02" title="Our Process" />
          <div className="op-container">
            <div className="op-container__image">
              <SVGImage className="our-process__img-container" type={MEASURING} />
            </div>
            <div className="op-container__text-content">
              <div className="our-process__big-heading">We take great pride in our ability to deliver successful building construction with the highest quality craftmanship.</div>
              <ul className="our-process__accordions">{jsxAccordions}</ul>
              <Cta
                action={() => alert('Coming soon, please check back later.')}
                className={`our-process__cta ${activeAccordionId === lastAccordionId ? 'our-process__cta--last' : ''}`}
                color={Colour.White}
                ctaText="See Our Services (coming soon)"
              />
            </div>
          </div>
        </div>

      </div>
    );
  }

  return <p>There was a problem determining the browser's screen width.</p>
};

export default Business;
