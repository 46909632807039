// Hooks
import { useViewport } from '../../../../hooks/useViewport';

// JSX
import Cta from '../../../../components/cta';
import Subheader from '../../../../components/subheader/subheader';
import SVGImage, { INSTALLPREP } from '../../../../components/svg';

// Styles
import './who-we-are.scss';

// Types
import { Colour, ScreenSize } from '../../../../types/enum';

const Business = (): JSX.Element => {
  const { screenSize } = useViewport();
  if (screenSize === ScreenSize.X_SMALL || screenSize === ScreenSize.SMALL) return <XsmallAndSmall />
  if (screenSize === ScreenSize.INTERMEDIATE || screenSize === ScreenSize.LARGE || screenSize === ScreenSize.X_LARGE) return <Intermediate />
  return <p>There was a problem determining the browser's screen width.</p>
};

function XsmallAndSmall(): JSX.Element {
  return (
    <div className="business">
      <div className="y-wrap y-wrap--inner">
        <div className="business__main">
          <Subheader num="01" title="Who We Are" />
          <div className="business__big-heading">A family-run<br />business</div>
          <p>MDMc Construction endeavors to perform quality work within a framework of integrity and responsibility</p>
          <Cta
            action={() => alert('hey')}
            className='business__cta'
            color={Colour.White}
            ctaText="Learn More"
          />
        </div>
      </div>
      <div className="y-wrap y-wrap--after768">
        <SVGImage className="business__img-container" type={INSTALLPREP} />
      </div>
    </div>
  );
}

function Intermediate(): JSX.Element {
  return (
    <div className="business business--intermediate">
      <div className="y-wrap y-wrap--inner">
        <Subheader num="01" title="Who We Are" />
        <div className="b-container">
          <div className="b-container__main">
            <div className="b-container__big-heading">MDMc Construction is a general<br />construction contracting company<br />that endeavors to perform quality<br />work within a framework of<br />integrity and responsibility.</div>
            <Cta
              action={() => alert('hey')}
              className="b-container__cta"
              color={Colour.White}
              ctaText="Learn More"
              tempHref="#team"
            />
          </div>
          <div className="b-container__secondary">
            <SVGImage className="business__img-container" type={INSTALLPREP} />
            <p>We are a small company with a large focus on healthcare and commercial construction. Our experience includes large, medium and small projects spanning commercial, government and healthcare facilities. We provide a high degree of integrity and professionalism with every project, devoted to completing your project no matter how restrictive the environment or the type of construction.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Business;
