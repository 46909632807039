const OpenBtn = (): JSX.Element => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.4" stroke="#505A80" strokeWidth="1.2"/>
      <path d="M12 16H20" stroke="#505A80" strokeWidth="1.2"/>
      <path d="M16 12L16 20" stroke="#505A80" strokeWidth="1.2"/>
    </svg>
  );
}

export default OpenBtn;