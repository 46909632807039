import { MouseEventHandler } from 'react';
import './style.scss';

interface ModalProps {
  action: MouseEventHandler<HTMLButtonElement>;
  message: string;
}

const Modal = ({ action, message }: ModalProps): JSX.Element => {
  return (
    <div className="modal">
      <p>{message}</p>
      <button className="modal__button" onClick={action}>&#215;</button>
    </div>
  )
}

export default Modal;