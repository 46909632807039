import {
  ContactUs,
  Credits,
  LetsGetStarted,
  LogoWithScrollToTop,
  Navs,
  OfficeLocation,
  YourName
} from './subcomponents';
import './footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="y-wrap y-wrap--inner">
        {/* <LetsGetStarted /> */}
        {/* <YourName /> */}
        <LogoWithScrollToTop />
        <div className="footer__links-container">
          <ContactUs />
          <div>
            <OfficeLocation
              name = 'Head Office'
              street = '154 Reagan Drive'
              cityState = 'Brunswick, Georgia'
              zip = '31525'
            />
            <OfficeLocation
              name = 'Orlando Office Center'
              street = '1060 Woodcock Road, Ste 107'
              cityState = 'Orlando, Florida'
              zip = '32803'
            />
          </div>
          <Navs />
        </div>
        <Credits />
      </div>
    </footer>
  );
}

export default Footer;
