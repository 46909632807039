import { FunctionComponent } from 'react';

// JSX
import LandingBanner from './banners/landing';
import CloseBtn from './buttons/close';
import Cta from './buttons/cta';
import NavBtn from './buttons/nav';
import OpenBtn from './buttons/open';
import AmRedCrossLogo from './icons/certifications/american-red-cross/american-red-cross';
import AsheLogo from './icons/certifications/ashe/ashe';
import ChcLogo from './icons/certifications/chc/chc';
import EVerifyLogo from './icons/certifications/e-verify/e-verify';
import DownloadPDF from './icons/downloadPDF/downloadPDF';
import Logo from './icons/logo';
import InstallPrep from './images/install-prep/install-prep';
import Measuring from './images/measuring/measuring';

// Types
import { SVGImageProps } from '../../types/interface';

// Styles
import './style.scss';

export const AMREDCROSS: string = 'amRedCross';
export const ASHE: string = 'ashe';
export const CHC: string = 'chc';
export const CLOSEBTN: string = 'closebtn';
export const CTA: string = 'cta';
export const DOWNLOADPDF: string  = 'downloadPDF';
export const EVERIFY: string = 'everify';
export const INSTALLPREP: string = 'installPrep';
export const LANDINGBANNER: string = 'landingBanner';
export const LOGO: string  = 'logo';
export const MEASURING: string = 'measuring';
export const NAVICON: string = 'navIcon';
export const OPENBTN: string = 'openbtn';

const SVGImage: FunctionComponent<SVGImageProps> = (
  {
    action,
    bgColor,
    className,
    color,
    direction,
    id,
    type
  }: SVGImageProps): JSX.Element => {
  const IconMap = {
    [AMREDCROSS]: (<AmRedCrossLogo />),
    [ASHE]: (<AsheLogo />),
    [CHC]: (<ChcLogo />),
    [CLOSEBTN]: (<CloseBtn />),
    [CTA]: (<Cta color={color} />),
    [DOWNLOADPDF] : (<DownloadPDF />),
    [EVERIFY]: (<EVerifyLogo />),
    [INSTALLPREP]: (<InstallPrep />),
    [LANDINGBANNER]: (<LandingBanner />),
    [LOGO] : (<Logo color={color} />),
    [MEASURING]: (<Measuring />),
    [NAVICON]: (<NavBtn color={color} direction={direction} />),
    [OPENBTN]: (<OpenBtn />)
  };

  // TODO combine these and use a ternary to send the `id`
  return (
    <>
      {id && (
        <button
          className={className}
          id={id}
          onClick={action}
          style={{backgroundColor: bgColor}}
        >
          {IconMap[type]}
        </button>
      )}
      {!id && (
        <div className={className} onClick={action}>
          {IconMap[type]}
        </div>
      )}
    </>
  )
};

export default SVGImage;
