// Assets
import LogoBlue from './logo-blue.png';
import LogoWhite from './logo-white.png';

// Types
import { Color } from '../../../../types/interface';

const Logo = ({color}: Color): JSX.Element => {
  const ImageMap = {
    BLACK: LogoBlue,  // TODO remove this, it's here only to keep TypeScript happy
    BLUE: LogoBlue,
    WHITE: LogoWhite
  };
  
  // TODO Since `color` can be `undefined`, implement a fallback image that indicates an error instead of `LogoWhite`.
  const source = color ? ImageMap[color] : LogoWhite;
  return <img alt="MDMc Logo" src={source} />
};

export default Logo;
