import { useState } from "react";
import LeaderImage from "../../components/leaderImage/leaderImage";
import Modal from "../../components/modal/modal";
import './style.scss';

const Contact = () => {

  const defaultFields = {
    email: '',
    message: '',
    name: '',
    telephone: ''
  };

  const [fields, setFields] = useState(defaultFields);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e: any) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value
    })
  };

  const postData = async (url: string, data: any) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json();
  };

  const handleResponse = (res: {isSent: boolean, message: string}) => {
    if (res.isSent) {
      setFields(defaultFields);
      setShowModal(true);
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // TODO implement env variables
    postData('https://sj-mdmcusa-dev.herokuapp.com/mail', fields).then(data => handleResponse(data));
    // postData('http://localhost:8080/mail', fields).then(data => handleResponse(data));
  }

  return (
    <>
      <LeaderImage altText="tbd" imageURL="https://placekitten.com/g/500/400" tagline="Get in touch.<br />We respond within 2 hours." />
      <div className="y-wrap y-wrap--inner">
        <div className="contact-block">
          <form onSubmit={handleSubmit}>
            <div className="contact-block__field">
              <input name="name" onChange={handleChange} placeholder="Name" value={fields.name}/>
            </div>
            <div className="contact-block__field">
              <input name="email" onChange={handleChange} placeholder="Email" value={fields.email}/>
              </div>
            <div className="contact-block__field">
              <input name="telephone" onChange={handleChange} placeholder="Telephone" value={fields.telephone}/>
              </div>
            <div className="contact-block__field">
              <textarea name="message" onChange={handleChange} placeholder="Tell us about your project" value={fields.message}></textarea>
            </div>
            <div className="contact-block__button">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
        {showModal && <Modal action={() => setShowModal(false)} message="Message sent" />}
      </div>
    </>
  );
};

export default Contact;
