import { CSSProperties } from 'react';

// JSX
import SVGImage, { CTA } from '../../components/svg';

// Styles
import './style.scss';

// Types
import { Colour, CtaType } from '../../types/enum';
import { CtaProps } from '../../types/interface';

const Cta = ({
    action,
    className,
    color,
    ctaText,
    tempHref,
    type = CtaType.Large
  }: CtaProps): JSX.Element => {
  // let backgroundColor = 'transparent'; // TODO determine if we can delete this
  let fontSize = '16px';
  let padding = '17px 22px';
  let width = '306px';

  let classListThatModifies = ` cta--${color.toLowerCase()}`;

  // TODO determine if we can delete
  // set `background-color`
  // if (type !== CtaType.Small && color === Colour.Blue) {
  //   backgroundColor = '#FFF';
  //   classListThatModifies += ' cta--blue';
  // }
  // if (type !== CtaType.Small && color === Colour.White) {
  //   backgroundColor = '#2251FF';
  // }
  if (type !== CtaType.Small) {
    classListThatModifies += ' cta--compact';
  }

  // set `fontSize`, `padding`, and `width`
  if (type === CtaType.Ordinary) {
    fontSize = '14px';
    width = '205px';
  }
  if (type === CtaType.Small) {
    fontSize = '14px';
    padding = '0';
    width = '119px';
  }

  const buttonStyles: CSSProperties = {
    // backgroundColor,  // TODO determine if we can delete
    fontSize,
    padding,
    width
  };

  // return (
  //   <div className={className}>
  //     <button className={`cta${classListThatModifies}`} onClick={action} style={buttonStyles}>
  //       <span>{ctaText}</span>
  //       <SVGImage color={color} type={CTA} />
  //     </button>
  //   </div>
  // )

  return (
    <div className={className}>
      <a className={`cta${classListThatModifies}`} href={tempHref} style={buttonStyles}>
        <span>{ctaText}</span>
        <SVGImage color={color} type={CTA} />
      </a>
    </div>
  )
}

export default Cta;
