import { Route, Routes } from 'react-router-dom';
import About from "./about/about";
import Contact from "./contact/contact";
import Credential from './credential/credential';
import Hiring from './hiring/hiring';
import Landing from "./landing/landing";
import PrivacyPolicy from "./privacyPolicy/privacyPolicy";
import Project from "./project/project";

const View = (): JSX.Element => {

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="credentials" element={<Credential />} />
      <Route path="careers" element={<Hiring />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      <Route path="projects" element={<Project />} />
    </Routes>
  );
};

export default View;