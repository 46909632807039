import { OfficeLocationProps } from "../../../types/interface";

const OfficeLocation = ({name, street, cityState, zip}: OfficeLocationProps) : JSX.Element => {
  return (
    <div className="footer__contact-container contact-container">
      <div className="contact-container__heading">{name}</div>
      <address>
        <span>{street}</span>
        <span>{cityState}</span>
        <span>{zip}</span>
      </address>
    </div>
  )
};

export default OfficeLocation;