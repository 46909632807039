// Data
import projectData from './projects.json';

// Hooks
import { useViewport } from '../../../../hooks/useViewport';

// JSX
import Slider from '../../../../components/slider/slider';
import Subheader from '../../../../components/subheader/subheader';

// Styles
import './projects.scss';

// Types
import { BackgroundColor, Format, ScreenSize } from '../../../../types/enum';

const Business = (): JSX.Element => {
  const { screenSize } = useViewport();
  if (screenSize === ScreenSize.X_SMALL || screenSize === ScreenSize.SMALL) return <XsmallAndSmall />
  if (screenSize === ScreenSize.INTERMEDIATE || screenSize === ScreenSize.LARGE || screenSize === ScreenSize.X_LARGE) return <Intermediate />
  return <p>There was a problem determining the browser's screen width.</p>

  function XsmallAndSmall(): JSX.Element {
    return (
      <div className="projects" id="projects">
        <div className="y-wrap y-wrap--inner">
          <div className="projects__main">
            <Subheader num="03" title="Projects" />
            <div className="projects__big-heading">Extensive experience</div>
          </div>
          <Slider bgNavRight={BackgroundColor.White} data={projectData} format={Format.PROJECT} />
        </div>
      </div>
    );
  }

  function Intermediate(): JSX.Element {
    return (
      <div className="projects" id="projects">
        <div className="y-wrap y-wrap--inner">
          <div className="projects__main">
            <Subheader num="03" title="Projects" />
            <div className="projects__big-heading">Our extensive experience<br />enables us to manage many<br />differing construction methods.</div>
          </div>
          <Slider bgNavRight={BackgroundColor.Blue} data={projectData} format={Format.TEASER} />
        </div>
      </div>
    );
  }
};

export default Business;
