const ContactUs = (): JSX.Element => {
  return (
    <div className="footer__contact-container contact-container">
      <div className="contact-container__heading">Contact Us</div>
      <address className="heavyish">
        <a href="tel:9122758495">+1.912.275.8495</a>
        <a href="mailto:hello@mdmcusa.com">nancy@mdmcusa.com</a>
      </address>
    </div>
  )
};

export default ContactUs;