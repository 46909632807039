import { useEffect, useState } from 'react';

// JSX
import SVGImage, { NAVICON } from '../../../components/svg';
import Headshot from './headshot';
import SliderImage from './sliderImage';

// Types
import { BackgroundColor, Colour, NavDirection } from "../../../types/enum";
import { SliderChildProps } from "../../../types/type";

const MultipleSimultaneousSlider = ({action, bgNavRight, count, data, lastSlideNumber}: SliderChildProps): JSX.Element => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    // FRAGILE this calculation is based on the width set in `.y-wrap--inner`
    const viewportWidth = window.innerWidth;
    setWidth(viewportWidth * .93);
  }, []);

  const graphics = data.map((graphic, index) => (
    <SliderImage
      alt={graphic.alt}
      key={`${graphic.name}-${index}`}
      name={graphic.name}
      prefix={graphic.prefix}
    />
  ));

  const renderHeadshot = ((num: number) => {
    return (
      <Headshot
        image={graphics[num]}
        isHorizontal={data[num].isHorizontal}
        position={num === count ? 0 : 1}
        subtitle={data[num].subtitle}
        title={data[num].title}
      />
    )
  });

  const renderHeadshots = (num: number) => {
    // FRAGILE if the number of headshots change, initializations for headshots after `headshot_1` must be updated
    let headshot_1 = num;
    let headshot_2 = num + 1;
    let headshot_3 = num + 2;
    let headshot_4 = num + 3;
    let headshot_5 = num + 4;
    let headshot_6 = num + 5;
    let headshot_7 = num + 6;
    let headshot_8 = num + 7;
    let headshot_9 = num + 8;
    let headshot_10 = num + 9;
    let headshot_11 = num + 10;
    let headshot_12 = num + 11;
    if (num === 1) {
      headshot_12 = 0;
    }
    if (num === 2) {
      headshot_11 = 0;
      headshot_12 = 1;
    }
    if (num === 3) {
      headshot_10 = 0;
      headshot_11 = 1;
      headshot_12 = 2;
    }
    if (num === 4) {
      headshot_9 = 0;
      headshot_10 = 1;
      headshot_11 = 2;
      headshot_12 = 3;
    }
    if (num === 5) {
      headshot_8 = 0;
      headshot_9 = 1;
      headshot_10 = 2;
      headshot_11 = 3;
      headshot_12 = 4;
    }
    if (num === 6) {
      headshot_7 = 0;
      headshot_8 = 1;
      headshot_9 = 2;
      headshot_10 = 3;
      headshot_11 = 4;
      headshot_12 = 5;
    }
    if (num === 7) {
      headshot_6 = 0;
      headshot_7 = 1;
      headshot_8 = 2;
      headshot_9 = 3;
      headshot_10 = 4;
      headshot_11 = 5;
      headshot_12 = 6;
    }
    if (num === 8) {
      headshot_5 = 0;
      headshot_6 = 1;
      headshot_7 = 2;
      headshot_8 = 3;
      headshot_9 = 4;
      headshot_10 = 5;
      headshot_11 = 6;
      headshot_12 = 7;
    }
    if (num === 9) {
      headshot_4 = 0;
      headshot_5 = 1;
      headshot_6 = 2;
      headshot_7 = 3;
      headshot_8 = 4;
      headshot_9 = 5;
      headshot_10 = 6;
      headshot_11 = 7;
      headshot_12 = 8;
    }
    if (num === 10) {
      headshot_3 = 0;
      headshot_4 = 1;
      headshot_5 = 2;
      headshot_6 = 3;
      headshot_7 = 4;
      headshot_8 = 5;
      headshot_9 = 6;
      headshot_10 = 7;
      headshot_11 = 8;
      headshot_12 = 9;
    }
    if (num === 11) {
      headshot_2 = 0;
      headshot_3 = 1;
      headshot_4 = 2;
      headshot_5 = 3;
      headshot_6 = 4;
      headshot_7 = 5;
      headshot_8 = 6;
      headshot_9 = 7;
      headshot_10 = 8;
      headshot_11 = 9;
      headshot_12 = 10;
    }
    return (
      <>
        {renderHeadshot(headshot_1)}
        {renderHeadshot(headshot_2)}
        {renderHeadshot(headshot_3)}
        {renderHeadshot(headshot_4)}
        {renderHeadshot(headshot_5)}
      </>
    );
  };

  const renderNavBtn = ((direction: NavDirection) => {
    let nextIndex = count - 1;
    let postfix = 'left';
    if (direction === NavDirection.Right) {
      postfix = 'right';
      nextIndex = count + 1;
    }
    return (
      <SVGImage
        action={() => action(nextIndex)}
        bgColor={direction === NavDirection.Right ? BackgroundColor.White : BackgroundColor.LtGray}
        className={`slider__nav slider__nav--${postfix}`}
        color={Colour.Black}
        direction={direction}
        id={`projects-nav-${postfix}`}
        type={NAVICON}
      />
    );
  });

  return (
    <>
      <div className="slider__graphic" style={{width: `${width}px`}}>
        <div className="slider__graphic-subcontainer">
          {renderHeadshots(count)}
        </div>
      </div>
      <div className="slider__navs-container">
        <div className="slider__navs">
          <div className="count-container">
            <span className="count-container__position">{count + 1}</span>
            <span className="count-container__of">&#8212;</span>{/* TODO use graphic instead of HTML entity */}
            <span>{lastSlideNumber}</span>
          </div>
          <div className="slider__navs-nav">
            {renderNavBtn(NavDirection.Left)}
            {renderNavBtn(NavDirection.Right)}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipleSimultaneousSlider;
