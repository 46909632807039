const Credits = (): JSX.Element => {
  // TODO calculate current year programmatically for the copyright
  return (
    <div className="footer__credits">
      <p>&#169; 2023 M.D. McDonald Contruction Co. All Rights Reserved.</p>
      <p>Web Development by <a href="https://github.com/samuraijane">@samuraijane</a>.</p>
      <p>Website Design by <a href="https://noat.me/">amelianashdesign.com</a>.</p>
    </div>
  )
};

export default Credits;