// Data
import licenseData from './credentials.json';

// JSX
import Subheader from '../../../../components/subheader/subheader';
import SVGImage, { AMREDCROSS, ASHE, CHC, EVERIFY } from '../../../../components/svg';

// Styles
import './credentials.scss';

const Credentials = (): JSX.Element => {

  const licenses = licenseData.map((license, index) => (
    <li key={`${license}-${index}`}>
      <div>{license.state}</div>
      <div className="credentials__license">{license.licenseNo}</div>
    </li>
  ));

  return (
    <div className="credentials">
      <div className="y-wrap y-wrap--inner">
        <Subheader modifyClass="subheader--white" num="05" title="Credentials" />
        <div className="credentials__big-heading">Certified to exceed your expectations</div>
        <div className="credentials__container">
          <div className="blah">
            <h4 className="credentials__h4 credentials__h4--licenses">Licenses</h4>
            <ul className="credentials__licenses">
              {licenses}
            </ul>
          </div>
          <div className="credentials__logos logos">
            <h4 className="credentials__h4">Certifications</h4>
            <div className="logos__logos-container">
              <SVGImage className="credentials__logo credentials__logo--ashe" type={ASHE} />
              <SVGImage className="credentials__logo credentials__logo--chc" type={CHC} />
              <SVGImage className="credentials__logo" type={EVERIFY} />
              <SVGImage className="credentials__logo credentials__logo--amredcross" type={AMREDCROSS} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Credentials;

